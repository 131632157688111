html {
  scroll-behavior: smooth;
  background-color: rgba(35,35,45,1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.admin-input input:-webkit-autofill,
.admin-input input:-webkit-autofill:hover, 
.admin-input input:-webkit-autofill:focus, 
.admin-input input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #f3e5f5 inset !important;
}

.admin-input input:-webkit-autofill {
  -webkit-text-fill-color: #212121 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px rgba(35,35,45,1) inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: whitesmoke;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(35,35,45,1);    /* color of the scroll thumb */
  border-radius: 5px;       /* roundness of the scroll thumb */
  border: 2px solid whitesmoke;  /* creates padding around scroll thumb */
}